<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useUserSession } from '/@src/stores/userSession'
import { isNutualRentDomain } from '/@src/utils/nutual-rent'
import { isInvernestDomain } from '/@src/utils/invernest'
import { useRegisterSW } from 'virtual:pwa-register/vue'

const isNutualRent = isNutualRentDomain()
const isInvernest = isInvernestDomain()
const router = useRouter()
const { updateServiceWorker, needRefresh } = useRegisterSW()

onMounted(async () => {
  try {
    if (needRefresh) await updateServiceWorker()

    const userSession = useUserSession()
    if (userSession.isLoggedIn) {
      router.push({
        name: 'app',
      })
    } else if (isInvernest) {
      router.push({
        name: 'auth-login',
      })
    } else if (isNutualRent) {
      router.push({
        name: 'explore',
      })
    } else {
      router.push({
        name: 'obtener-oferta',
      })
    }
  } catch (e) {
    console.log(e)
    router.push({
      name: 'explore',
    })
  }
})
</script>

<template>
  <div></div>
</template>
