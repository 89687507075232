<script setup lang="ts">
import { useRoute } from 'vue-router'
import { isInvernestDomain } from '/@src/utils/invernest'
import { isNutualRentDomain } from '/@src/utils/nutual-rent'
import { useHead } from '@vueuse/head'

const isInvernest = isInvernestDomain()
const isNutualRent = isNutualRentDomain()
const route = useRoute()
let link = document.querySelector("link[rel~='icon']")

if (isNutualRent) {
  useHead({
    meta: [{ name: 'Author', content: 'https://www.nutual-rent.com' }],
  })
  link.href = '/favicon-nutual-tenant-32x32.png'
} else if (isInvernest) {
  useHead({
    meta: [{ name: 'Author', content: 'https://www.invernest.com' }],
  })
  link.href = '/favicon-invernest-32x32.png'
} else {
  useHead({
    meta: [{ name: 'Author', content: 'https://www.nutual.com' }],
  })
  link.href = '/cropped-favicon-32x32.png'
}
</script>
<template>
  <div>
    <Suspense>
      <RouterView :key="route.path" v-slot="{ Component }">
        <Transition name="fade-slow" mode="out-in">
          <div>
            <component :is="Component" />
          </div>
        </Transition>
      </RouterView>
    </Suspense>
    <heyflow />
  </div>
</template>
