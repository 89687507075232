import axios, { AxiosInstance } from 'axios'

import { useUserSession } from '/@src/stores/userSession'

let api: AxiosInstance
let udaApi: AxiosInstance
let nutualApi: AxiosInstance

export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  })

  // We set an interceptor for each request to
  // include Bearer token to the request if user is logged in
  api.interceptors.request.use((config) => {
    const userSession = useUserSession()

    if (userSession.isLoggedIn) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${userSession.getToken()}`,
      }
    }

    return config
  })

  return api
}

export function createUdaApi() {
  // Here we set the base URL for all requests made to the api
  udaApi = axios.create({
    baseURL: import.meta.env.VITE_NUTUAL_API_BASE_URL,
  })

  // We set an interceptor for each request to
  // include x-token to the request
  udaApi.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      'x-token': 'u8BS9XdT1mAm28yb3cOqHWx1K5',
    }

    return config
  })

  return udaApi
}

export function createNutualApi() {
  // Here we set the base URL for all requests made to the api
  nutualApi = axios.create({
    baseURL: import.meta.env.VITE_NUTUAL_API_BASE_URL,
  })

  return nutualApi
}

export function useApi() {
  if (!api) {
    createApi()
  }
  return api
}
export function useUdaApi() {
  if (!udaApi) {
    createUdaApi()
  }
  return udaApi
}
export function useNutualApi() {
  if (!nutualApi) {
    createNutualApi()
  }
  return nutualApi
}
