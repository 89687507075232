import { useStorage } from '@vueuse/core'

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: true,
  }

  const opt = {
    ...defaultOptions,

    ...options,
  }

  const defaultLocale = useStorage('locale', '')

  if (defaultLocale) return [defaultLocale.value]

  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages

  if (!browserLocales) {
    return ['en']
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim()

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale
  })
}
let language = getBrowserLocales()
if (language.includes('en') || language.includes('es')) {
  language = getBrowserLocales()
} else {
  language = ['en']
}
export const browserLanguage = language
