//import { useStorage } from '@vueuse/core'
import { createI18n as createClientI18n } from 'vue-i18n'
import { useStorage } from '@vueuse/core'
/**
 * messages are generated using vite-plugin-i18n
 * each .json files located in the ./src/locales are registered in messages
 * @see https://github.com/intlify/bundle-tools/tree/main/packages/vite-plugin-vue-i18n
 */
import messages from '@intlify/vite-plugin-vue-i18n/messages'
//import { browserLanguage } from '/@src/stores/personalAreaInfo'

export function createI18n() {
  //const defaultLocale = useStorage('locale', browserLanguage[0])
  const defaultLocale = useStorage('locale', '')
  if (!defaultLocale.value) {
    defaultLocale.value = navigator?.language.split('-')[0] || 'es'
  }
  //const defaultLocale = 'en'
  const i18n = createClientI18n({
    locale: defaultLocale.value,
    messages,
  })

  return i18n
}
