/**
 * Theses types are used by the Wizard form
 *
 * @see /src/pages/obtener-oferta/index.vue
 * @see /src/components/partials/onboarding-wizard
 */
export const NO_INDICADO: string = 'No indicado'
export interface User {
  id: number
  full_name: string
  email: string
  is_active: boolean
  role: string
  is_email_verified?: boolean
  is_password_set?: boolean
  language?: string
}
export interface AccessToken {
  access_token: string
  token_type: string
}
export interface AccessTokenIsNewUser {
  access_token: string
  token_type: string
  is_new_user: boolean
}
export interface AppErrorType extends Error {
  status: number
}
export interface PersonalInfo {
  id: number
  email: string
  full_name?: string
  phone?: string
  organization?: string
  customer_id?: number
}
