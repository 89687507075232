import { computed, reactive } from 'vue'
import { createSharedComposable, useCssVar } from '@vueuse/core'
import { HSLToHex } from '/@src/utils/color-converter'

export const useThemeColors = createSharedComposable(() => {
  const primary = useCssVar('--primary', document.documentElement)
  const success = useCssVar('--success', document.documentElement)
  const info = useCssVar('--info', document.documentElement)
  const warning = useCssVar('--warning', document.documentElement)
  const danger = useCssVar('--danger', document.documentElement)
  const purple = useCssVar('--purple', document.documentElement)
  const blue = useCssVar('--blue', document.documentElement)
  const green = useCssVar('--green', document.documentElement)
  const yellow = useCssVar('--yellow', document.documentElement)
  const orange = useCssVar('--orange', document.documentElement)

  const themeColors = reactive({
    primary: computed(() => HSLToHex(primary.value)),
    primaryMedium: '#b4e4ce',
    primaryLight: '#f7fcfa',
    secondary: '#ff227d',
    accent: '#797bf2',
    accentMedium: '#d4b3ff',
    accentLight: '#b8ccff',
    success: computed(() => HSLToHex(success.value)),
    info: computed(() => HSLToHex(info.value)),
    warning: computed(() => HSLToHex(warning.value)),
    danger: computed(() => HSLToHex(danger.value)),
    purple: computed(() => HSLToHex(purple.value)),
    blue: computed(() => HSLToHex(blue.value)),
    green: computed(() => HSLToHex(green.value)),
    yellow: computed(() => HSLToHex(yellow.value)),
    orange: computed(() => HSLToHex(orange.value)),
    lightText: '#a2a5b9',
    fadeGrey: '#ededed',
  } as const)

  return themeColors
})

export const useClientColors = () => {
  const primaryColorHueVar = useCssVar('--primary-h')
  const primaryColorSaturationVar = useCssVar('--primary-s')
  const primaryColorLuminanceVar = useCssVar('--primary-l')
  const clientColorHueVar = useCssVar('--client-h')
  const clientColorSaturationVar = useCssVar('--client-s')
  const clientColorLuminanceVar = useCssVar('--client-l')
  primaryColorHueVar.value = clientColorHueVar.value
  primaryColorSaturationVar.value = clientColorSaturationVar.value
  primaryColorLuminanceVar.value = clientColorLuminanceVar.value
  const primaryColorInvertVar = useCssVar('--primary--color-invert')
  const primaryColorDarkVar = useCssVar('--primary--dark-color')
  const primaryColorLightVar = useCssVar('--primary--light-color')
  primaryColorInvertVar.value = '#FFFFFF'
  primaryColorDarkVar.value = '#E60073'
  primaryColorLightVar.value = '#FFEBF5'
}
export const usePartnerColors = () => {
  const primaryColorHueVar = useCssVar('--primary-h')
  const primaryColorSaturationVar = useCssVar('--primary-s')
  const primaryColorLuminanceVar = useCssVar('--primary-l')
  const partnerColorHueVar = useCssVar('--partner-h')
  const partnerColorSaturationVar = useCssVar('--partner-s')
  const partnerColorLuminanceVar = useCssVar('--partner-l')
  primaryColorHueVar.value = partnerColorHueVar.value
  primaryColorSaturationVar.value = partnerColorSaturationVar.value
  primaryColorLuminanceVar.value = partnerColorLuminanceVar.value
  const primaryColorInvertVar = useCssVar('--primary--color-invert')
  const primaryColorDarkVar = useCssVar('--primary--dark-color')
  const primaryColorLightVar = useCssVar('--primary--light-color')
  primaryColorInvertVar.value = '#FFFFFF'
  primaryColorDarkVar.value = '#0387C9'
  primaryColorLightVar.value = '#EBF8FF'
}

export const useClientCAColors = () => {
  const primaryColorHueVar = useCssVar('--primary-h')
  const primaryColorSaturationVar = useCssVar('--primary-s')
  const primaryColorLuminanceVar = useCssVar('--primary-l')
  const clientCAColorHueVar = useCssVar('--clientCA-h')
  const clientCAColorSaturationVar = useCssVar('--clientCA-s')
  const clientCAColorLuminanceVar = useCssVar('--clientCA-l')
  primaryColorHueVar.value = clientCAColorHueVar.value
  primaryColorSaturationVar.value = clientCAColorSaturationVar.value
  primaryColorLuminanceVar.value = clientCAColorLuminanceVar.value
  const primaryColorInvertVar = useCssVar('--primary--color-invert')
  const primaryColorDarkVar = useCssVar('--primary--dark-color')
  const primaryColorLightVar = useCssVar('--primary--light-color')
  primaryColorInvertVar.value = '#FFFFFF'
  primaryColorDarkVar.value = '#008216'
  primaryColorLightVar.value = '#FFEBF5'
}
