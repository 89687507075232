import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useHeyflow = defineStore('heyflow', () => {
  const flowId = ref('properplan-f1')
  const open = ref(false)

  function setFlowId(value: string) {
    flowId.value = value
  }
  function setOpen(value: boolean) {
    open.value = value
  }

  return {
    flowId,
    open,
    setFlowId,
    setOpen,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHeyflow, import.meta.hot))
}
