import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { useStorage } from '@vueuse/core'
import { AxiosError } from 'axios'
import { useApi } from '../composable/useApi'
import {
  AccessTokenIsNewUser,
  AccessToken,
  User,
  PersonalInfo,
  NO_INDICADO,
} from '../models/auth'
import { AppError } from '../utils/app-error'

export type ProductSignupRequest = {
  full_name: string
  email: string
  referral_id?: string
}
export type SignupRequest = {
  full_name: string
  email: string
  password: string
}
export type SigninRequest = {
  username: string
  password: string
}
export type ProviderSigninRequest = {
  provider: 'google'
  credential: any
}

export type UserData = User | null

export const useUserSession = defineStore('userSession', () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  const token = useStorage('token', '')
  const tokenThatExpires = ref('')

  const user = ref<UserData>()
  const personalInfo = ref<PersonalInfo>()
  const loading = ref(true)
  const rememberToken = ref(true)

  const isLoggedIn = computed(
    () =>
      (token.value !== undefined && token.value !== '') ||
      (tokenThatExpires.value !== undefined && tokenThatExpires.value !== '')
  )

  function setUser(newUser: UserData) {
    user.value = _fixUserNoIndicado(newUser)
  }
  async function getUser(): Promise<User> {
    if (user.value) {
      return user.value
    }
    try {
      const { data: user } = await useApi().get(`/api/v1/users/me`)
      setUser(user)
      const defaultLocale = useStorage('locale', '')
      if (user.language) {
        defaultLocale.value = user.language
      } else {
        defaultLocale.value = navigator?.language.split('-')[0] || 'es'
      }

      return user
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }

  const _fixUserNoIndicado = (user: UserData) => {
    if (user) {
      user.full_name = user.full_name !== NO_INDICADO ? user.full_name : ''
    }
    return user
  }

  async function getPersonalInfo(): Promise<PersonalInfo> {
    if (personalInfo.value) {
      return personalInfo.value
    }
    try {
      const { data: pi } = await useApi().get(`/api/v1/users/me/personalinfo`)
      setPersonalInfo(pi)
      return pi
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  function setPersonalInfo(newPersonalInfo: PersonalInfo) {
    personalInfo.value = _fixPersonalInfoNoIndicado(newPersonalInfo)
  }

  const _fixPersonalInfoNoIndicado = (pi: PersonalInfo) => {
    pi.full_name = pi.full_name !== NO_INDICADO ? pi.full_name : ''
    pi.organization = pi.organization !== NO_INDICADO ? pi.organization : ''
    pi.phone = pi.phone !== NO_INDICADO ? pi.phone : ''
    return pi
  }

  function setToken(newToken: string) {
    if (rememberToken.value) {
      token.value = newToken
    } else {
      tokenThatExpires.value = newToken
    }
  }
  function getToken(): string {
    if (rememberToken.value) {
      return token.value
    } else {
      return tokenThatExpires.value
    }
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  function setRememberToken(newRememberToken: boolean) {
    rememberToken.value = newRememberToken
  }

  async function logoutUser() {
    token.value = undefined
    user.value = undefined
  }

  async function signup(data: SignupRequest): Promise<User> {
    try {
      const { data: user } = await useApi().post(`/api/v1/users/open`, data)
      setUser(user)
      return user
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function productSignup(product: string, data: ProductSignupRequest) {
    try {
      const { data: resp } = await useApi().post(`/api/v1/users/open/${product}`, data)

      if (product === 'NUTUAL_RENT') {
        setToken(resp.token['access_token'])
        setUser(null)
      }

      return resp
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function signin(data: SigninRequest): Promise<AccessToken> {
    const params = new URLSearchParams()
    params.append('username', data.username)
    params.append('password', data.password)
    try {
      const { data: accessToken } = await useApi().post(
        `/api/v1/login/access-token`,
        params,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      setToken(accessToken.access_token)
      setUser(null)
      return accessToken
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function forgotPassword(email: string): Promise<void> {
    try {
      await useApi().post(`/api/v1/password-recovery/${email}`)
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function resetPassword(token: string, newPassword: string): Promise<String> {
    try {
      const { data: data } = await useApi().post(`/api/v1/reset-password`, {
        token,
        new_password: newPassword,
      })
      return data.msg
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function verifyEmail(token: string): Promise<String> {
    try {
      const { data: data } = await useApi().post(`/api/v1/verify-email`, {
        token,
      })
      return data.msg
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }
  async function providerSignin(
    product: 'VI' | 'CA' | 'INVERNEST' | 'NUTUAL_RENT',
    data: ProviderSigninRequest
  ): Promise<AccessTokenIsNewUser> {
    const params = new URLSearchParams()
    params.append('credential', data.credential)

    try {
      const { data: accessToken } = await useApi().post(
        `/api/v1/${product}/login/provider/${data.provider}`,
        params,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      setToken(accessToken.access_token)
      setUser(null)
      return accessToken
    } catch (e) {
      const error = e as AxiosError
      console.log(error.response)
      throw new AppError(error.response!.data.detail, error.response!.status)
    }
  }

  return {
    user,
    token,
    isLoggedIn,
    loading,
    rememberToken,
    logoutUser,
    setUser,
    setToken,
    getToken,
    setLoading,
    signup,
    productSignup,
    signin,
    providerSignin,
    getUser,
    forgotPassword,
    resetPassword,
    setRememberToken,
    getPersonalInfo,
    verifyEmail,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
