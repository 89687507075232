import { createApp as createClientApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
import Hotjar from 'vue-hotjar'
import { createI18n } from './i18n'
import { createRouter } from './router'
import VueroApp from './VueroApp.vue'
import VueAppleLogin from 'vue-apple-login'
import './styles'

//import { initDarkmode } from '/@src/stores/darkmode'
import { createApi } from '/@src/composable/useApi'
import vue3GoogleLogin from 'vue3-google-login'
import { Loader } from '@googlemaps/js-api-loader'

export type VueroAppContext = Awaited<ReturnType<typeof createApp>>

import { registerGlobalComponents, registerRouterNavigationGuards } from './app-custom'

export async function createApp() {
  const head = createHead()
  const meta = createMetaManager()
  const i18n = createI18n()
  const router = createRouter()
  const pinia = createPinia()
  const api = createApi()

  const app = createClientApp(VueroApp)

  const vuero = {
    app,
    api,
    router,
    i18n,
    head,
    meta,
    pinia,
  }

  await registerGlobalComponents(vuero)
  app.use(vuero.pinia)
  app.use(vuero.head)
  app.use(vuero.meta)
  app.use(vuero.i18n)
  app.use(Hotjar, {
    id: '2775957',
    isProduction: import.meta.env.MODE === 'prod',
  })
  app.use(vue3GoogleLogin, {
    clientId: '609756418145-ur36tfcla3pmp1a3g785c6lei1p2b2fc.apps.googleusercontent.com',
    buttonConfig: {
      width: '280',
    },
  })
  const time: number = Date.now()
  const currentURL = window.location.href
  let redirectURL = import.meta.env.VITE_VI_DOMAIN

  if (currentURL.includes('invernest')) {
    redirectURL = import.meta.env.VITE_IN_DOMAIN
  } else if (currentURL.includes('nutual-rent')) {
    redirectURL = import.meta.env.VITE_NUTUAL_RENT
  }

  app.use(VueAppleLogin, {
    clientId: 'com.nutual.signinapple',
    scope: 'name email',
    redirectURI: redirectURL,
    state: time.toString(),
    usePopup: true,
  })

  registerRouterNavigationGuards(vuero)
  app.use(vuero.router)
  const loader = new Loader({
    apiKey: 'AIzaSyDXKgH_AOjE0C5o4JWGJZP2tCh-FwCZF6A',
    version: 'weekly',
    language: 'es',
    libraries: ['places', 'drawing'],
    region: 'Es',
  })
  loader.importLibrary('places')
  loader.importLibrary('drawing')

  return vuero
}
