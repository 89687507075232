import { AxiosError } from 'axios'

export class AppError extends Error {
  status: number
  constructor(message: string, status: number) {
    super(message)
    this.status = status
    Object.setPrototypeOf(this, AppError.prototype)
  }
}

export function handleError(error: AxiosError) {
  console.error(error.toJSON())
  if (error.response) {
    throw new Error(error.response.data)
  }
  throw new Error(error.message)
}
