<!-- eslint-disable vue/custom-event-name-casing -->
<script setup lang="ts">
import { watch, ref, onBeforeMount, onUnmounted } from 'vue'
import { useHeyflow } from '/@src/stores/heyflow'

const heyflowStore = useHeyflow()
const heyflowId = import.meta.env.VITE_HEYFLOW_ID
const styles = ref<string>('{"width": "90%", "margin-top":"100px"}')
const emit = defineEmits<{
  (e: 'heyflow:opened'): void
}>()

watch(
  () => heyflowStore.open,
  () => {
    if (heyflowStore.open) {
      window.dispatchEvent(
        new CustomEvent('heyflow-modal-element:open', {
          detail: { modalId: heyflowId },
        })
      )
      heyflowStore.setOpen(false)
      emit('heyflow:opened')
    }
  }
)

onBeforeMount(async () => {
  window.addEventListener('resize', () => handleResize())
  handleResize()
})

onUnmounted(async () => {
  window.removeEventListener('resize', () => handleResize())
})

const handleResize = () => {
  const width = window.innerWidth
  if (width > 1023 && width < 1216) {
    styles.value = '{"max-width": "960px", "margin-top":"100px"}'
  } else if (width >= 1216) {
    styles.value = '{"max-width": "1152px", "margin-top":"100px"}'
  } else {
    styles.value = '{"width": "95%", "margin-top":"100px"}'
  }
}
</script>
<template>
  <heyflow-modal-element :modal-id="heyflowId"
    ><heyflow-wrapper
      :flow-id="heyflowStore.flowId"
      dynamic-height
      :style-config="styles"
      :modal-id="heyflowId"
    ></heyflow-wrapper
  ></heyflow-modal-element>
</template>
